import {
	AvalancheConfig,
	AvalancheCurrenciesConfig,
	AvalancheExternalConfig,
	AvalancheExternalPoolsConfig,
} from '../../type/avalanche'

const currencies: AvalancheCurrenciesConfig = {
	ERC20: {
		crv: '0x47536F17F4fF30e64A96a7555826b8f9e66ec468',
		wbtc: '0x50b7545627a5162F82A992c33b87aDc75187B218',
		// link: '',
		// mim: '',
		// cvx: '',
		// yax: '',
		usdc: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
		dai: '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
		usdt: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
		// '3crv': '',
		weth: '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
		// mvlt: '',
		// spell: '',
		// frax: '',
		wavax: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
		joe: '0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd',
	},
	ERC677: {
		yaxis: '0x91A1700835230B8b3B06B5B3DD1Fe70D48ACbd91',
	},
}

const external: AvalancheExternalConfig = {
	multicall: '0x98e2060F672FD1656a07bc12D7253b5e41bF3876',
	joeRouter: '0x60aE616a2155Ee3d9A68541Ba4544862310933d4',
	joeMasterChef: '0x4483f0b6e2f5486d06958c20f8c39a7abe87bf8f',
	steakhutMasterChef: '0xddbfbd5dc3ba0feb96cb513b689966b2176d4c09', 
	aaveRewards: '0x01D83Fe6A10D2f2B7AF17034343746188272cAc9',
}

const externalPools: AvalancheExternalPoolsConfig = {
	curve: {
		av3crv: {
			currency: 'usd',
			pool: '0x7f90122BF0700F9E7e1F688fe926940E8839F353',
			token: '0x1337BedC9D22ecbe766dF105c9623922A27963EC',
			gauge: '0x5B5CFE992AdAC0C9D48E05854B2d91C73a003858',
			rewards: '0xb504b6eb06760019801a91b451d3f7bd9f027fc9',
		},
		atricrypto: {
			currency: 'usd',
			pool: '0xB755B949C126C04e0348DD881a5cF55d424742B2',
			token: '0x1daB6560494B04473A0BE3E7D83CF3Fdf3a51828',
			gauge: '0x445FE580eF8d70FF569aB36e80c647af338db351',
			rewards: '0xa05e565ca0a103fcd999c7a7b8de7bd15d5f6505',
		},
		aawbtcrencrv: {
			currency: 'usd',
			pool: '0x16a7DA911A4DD1d83F3fF066fE28F3C792C50d90',
			token: '0xC2b1DF84112619D190193E48148000e3990Bf627',
			gauge: '0x0f9cb53Ebe405d49A0bbdBD291A65Ff571bC83e1',
			rewards: '0x75d05190f35567e79012c2f0a02330d3ed8a1f74',
		},
	},
	aave: {
		avax: {
			currency: '',
			pool: '0x4F01AeD16D97E3aB5ab2B501154DC9bb0F1A5A2C',
			token: '0xDFE521292EcE2A4f44242efBcD66Bc594CA9714B',
			gauge: '',
			rewards: '',
		},
	},
	traderjoe: {
		wethavax: {
			currency: '',
			pool: '',
			token: '0xFE15c2695F1F920da45C30AAE47d11dE51007AF9',
			gauge: '',
			rewards: '',
		},
		usdcusdc: {
			currency: '',
			pool: '',
			token: '0x2a8a315e82f85d1f0658c5d66a452bbdd9356783',
			gauge: '',
			rewards: '',
		},
		avaxlink: {
			currency: '',
			pool: '',
			token: '0x6f3a0c89f611ef5dc9d96650324ac633d02265d3',
			gauge: '',
			rewards: '',
		},
		avaxusdt: {
			currency: '',
			pool: '',
			token: '0xbb4646a764358ee93c2a9c4a147d5aDEd527ab73',
			gauge: '',
			rewards: '',
		},
		avaxptp: {
			currency: '',
			pool: '',
			token: '0xcdfd91eea657cc2701117fe9711c9a4f61feed23',
			gauge: '',
			rewards: '',
		},
		avaxgohm: {
			currency: '',
			pool: '',
			token: '0xb674f93952f02f2538214d4572aa47f262e990ff',
			gauge: '',
			rewards: '',
		},
		avaxsavax: {
			currency: '',
			pool: '',
			token: '0x4b946c91c2b1a7d7c40fb3c130cdfbaf8389094d',
			gauge: '',
			rewards: '',
		},
		avaxwbtc: {
			currency: '',
			pool: '',
			token: '0xd5a37dc5c9a396a03dd1136fc76a1a02b1c88ffa',
			gauge: '',
			rewards: '',
		},
		avaxusdc: {
			currency: '',
			pool: '',
			token: '0xf4003f4efbe8691b60249e6afbd307abe7758adb',
			gauge: '',
			rewards: '',
		}
	},
	steakhut: {
		usdcjoe: {
			currency: '',
			pool: '',
			token: '0x3bc40d4307cd946157447cd55d70ee7495ba6140',
			gauge: '',
			rewards: '',
		},
		joewavax: {
			currency: '',
			pool: '',
			token: '0x454e67025631c065d3cfad6d71e6892f74487a15',
			gauge: '',
			rewards: '',
		},
	}
}

const mainnet: AvalancheConfig = {
	internal: {
		// Current
		manager: '0x831cde67d3A584E49ae57AfBc64d42545aBF9a1F',
		controller: '0xf853FbAc78eE9a592E105eBfCe5d0Bf877F845c8',
		vaultHelper: '0x95A901B5B85ae8996b4b9a2838e573Cb60C2F165',
		minter: '0xE4532E2433007C5f62B2ac537B54Db9D5aaab453',
		minterWrapper: '0xB5D82cDd557329cD1B16D89D1Cfdf2B274CFB0C4',
		votingEscrow: '0x9D71b871C5E5d192B48F983c4e52761c99DD9298',
		gaugeController: '0x11107B773e51A1030A8c7D27DACBED655992E384',
		feeDistributor: '0x53D26Dd410e7d6D54548903633e269444740c32a',
	},
	rewards: {
		'TraderJoe YAXIS/WAVAX': '0x261835a2933781063e15152B50b36B16378B1e61',
	},
	vaults: {
		av3crv: {
			url: 'https://avax.curve.fi/aave/deposit',
			tokenPoolContract: externalPools.curve.av3crv.pool,
			token: 'AV3CRV',
			tokenContract: externalPools.curve.av3crv.token,
			vault: '0x4FEf85d0EcBa72B92A3aE6909F02552b6E04A37d',
			vaultToken: 'CV:AV3CRV',
			vaultTokenContract: '0xe2f2Ed71481AA6c21b2D748ce17432EF7C062265',
			gauge: '0xE078741e5329487bf12908189b111D1b8266243E',
		},
		atricrypto: {
			url: 'https://avax.curve.fi/atricrypto/deposit',
			tokenPoolContract: externalPools.curve.atricrypto.pool,
			token: 'ATRICRYPTO',
			tokenContract: externalPools.curve.atricrypto.token,
			vault: '0xeecB776aF2a9F1B96953b1487CF5Cce7c304FdDB',
			vaultToken: 'CV:ATRICRYPTO',
			vaultTokenContract: '0x6a6C5A596De30a538F95B79b8C9c7B80eDc485EB',
			gauge: '0xc4101Cc2D62301c965713782c1CEF458164a4172',
		},
		avax: {
			url: 'https://app.aave.com/#/deposit',
			tokenPoolContract: externalPools.aave.avax.pool,
			token: 'WAVAX',
			payable: 'AVAX',
			tokenContract: currencies.ERC20.wavax,
			vault: '0x2046C733b0DA43b5A3e5a2F97A219B480eee1818',
			vaultToken: 'CV:AVAX',
			vaultTokenContract: '0xF81354F92A8c91484C54085aDcd9fDCfbF621Cf0',
			gauge: '0x69577301988ddc9032167C2E22D458e7A6A92244',
		},
		joewavax: {
			url: 'https://traderjoexyz.com/pool/0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd/AVAX#/',
			tokenPoolContract: externalPools.steakhut.joewavax.pool,
			token: 'JOEWAVAX',
			tokenContract: externalPools.steakhut.joewavax.token,
			vault: '0xaa6e94eb94246f8E4656a34c05C164a1A853172a',
			vaultToken: 'CV:JOEWAVAX',
			vaultTokenContract: '0xdD88A3B3051B72C5d32C4A94AFe494dfb75f7839',
			gauge: '0x2D89a857a76b00C2A0E9EFb016DF49F9CB049755',
		},
		wethavax: {
			url: 'https://traderjoexyz.com/pool/0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab/AVAX',
			tokenPoolContract: externalPools.traderjoe.wethavax.pool,
			token: 'WETHAVAX',
			tokenContract: externalPools.traderjoe.wethavax.token,
			vault: '0x715840DAB41CcCfB8f591A7c4DAaD78398049220',
			vaultToken: 'CV:WETHAVAX',
			vaultTokenContract: '0x5c0911b8C8C85Da266565a11137d6E6818D5d8B9',
			gauge: '0xb6526eA37479b0287cb9cF19C49ec87A324BC7De',
		},
		usdcusdc: {
			url: 'https://traderjoexyz.com/pool/0x2a8a315e82f85d1f0658c5d66a452bbdd9356783/AVAX',
			tokenPoolContract: externalPools.traderjoe.usdcusdc.pool,
			token: 'USDCUSDC',
			tokenContract: externalPools.traderjoe.usdcusdc.token,
			vault: '0x97Ce1cA55E7D936431B18934cFD624A820B2bD75',
			vaultToken: 'CV:USDCUSDC',
			vaultTokenContract: '0x32EBeD149F1bEc43fCBeFF47507b7B3497ce53dc',
			gauge: '0xc8cf240B7a6835Ea4d90e18C5e92aFa7C17E55B8',
		},
		// avaxlink: {
		// 	url: 'https://traderjoexyz.com/pool/0x6f3a0c89f611ef5dc9d96650324ac633d02265d3/AVAX',
		// 	tokenPoolContract: externalPools.traderjoe.avaxlink.pool,
		// 	token: 'AVAXLINK',
		// 	tokenContract: externalPools.traderjoe.avaxlink.token,
		// 	vault: '0x6A00EE9f76a1E65aB7dd0a4bc5e908f5107BA47E',
		// 	vaultToken: 'CV:AVAXLINK',
		// 	vaultTokenContract: '0x2fcD73F632bE7ad168487674EC7159C92AA6a6A2',
		// 	gauge: '0x474841675AC91862840149096b897CdE776c42A2',
		// },
		avaxusdt: {
			url: 'https://traderjoexyz.com/pool/0xbb4646a764358ee93c2a9c4a147d5aDEd527ab73/AVAX',
			tokenPoolContract: externalPools.traderjoe.avaxusdt.pool,
			token: 'AVAXUSDT',
			tokenContract: externalPools.traderjoe.avaxusdt.token,
			vault: '0x39A2Cc6DC87c00A30e25B6ec3e32F2C05302024D',
			vaultToken: 'CV:AVAXUSDT',
			vaultTokenContract: '0x53eB0477b2c8943c3ac91157e4C02D8BA9b6E8C0',
			gauge: '0x79F600BD1dDa86ECcc50e27886e74577E4bfCc60',
		},
		// avaxptp: {
		// 	url: 'https://traderjoexyz.com/pool/0xcdfd91eea657cc2701117fe9711c9a4f61feed23/AVAX',
		// 	tokenPoolContract: externalPools.traderjoe.avaxptp.pool,
		// 	token: 'AVAXPTP',
		// 	tokenContract: externalPools.traderjoe.avaxptp.token,
		// 	vault: '0x1B2f08dE4E95Aa42Cd6B49A22904810289898B03',
		// 	vaultToken: 'CV:AVAXPTP',
		// 	vaultTokenContract: '0xf1f248894f9E8B4B39ACD45a5db9Ee18B9738AAb',
		// 	gauge: '0x7B893bfb372b560F9845eEe78e8bDDaa2b89eAb4',
		// },
		// avaxgohm: {
		// 	url: 'https://traderjoexyz.com/pool/0xb674f93952f02f2538214d4572aa47f262e990ff/AVAX',
		// 	tokenPoolContract: externalPools.traderjoe.avaxgohm.pool,
		// 	token: 'AVAXGOHM',
		// 	tokenContract: externalPools.traderjoe.avaxgohm.token,
		// 	vault: '0x504112558e140D5A2F1e5Fb3F8BF204C2149d302',
		// 	vaultToken: 'CV:AVAXGOHM',
		// 	vaultTokenContract: '0x5316A33e1F82cfEF8eecF51156Da76F0dc75fe4f',
		// 	gauge: '0x28BEE4f8625770266a36f44f5b54afE9be5807FE',
		// },
		// avaxsavax: {
		// 	url: 'https://traderjoexyz.com/pool/0x4b946c91c2b1a7d7c40fb3c130cdfbaf8389094d/AVAX',
		// 	tokenPoolContract: externalPools.traderjoe.avaxsavax.pool,
		// 	token: 'AVAXSAVAX',
		// 	tokenContract: externalPools.traderjoe.avaxsavax.token,
		// 	vault: '0xE02719Da85c4441eeEC3a877f2932Be963dCa06f',
		// 	vaultToken: 'CV:AVAXSAVAX',
		// 	vaultTokenContract: '0x4754254f4afdB621614233790e86FDF38FDc6451',
		// 	gauge: '0x15Ac08e2e540a4a7F8075bFf1226026A85549CA7',
		// },
		// avaxwbtc: {
		// 	url: 'https://traderjoexyz.com/pool/0xd5a37dc5c9a396a03dd1136fc76a1a02b1c88ffa/AVAX',
		// 	tokenPoolContract: externalPools.traderjoe.avaxwbtc.pool,
		// 	token: 'AVAXWBTC',
		// 	tokenContract: externalPools.traderjoe.avaxwbtc.token,
		// 	vault: '0x0C9F0ACFF119886a640B92a5E3E6933b93b44eb1',
		// 	vaultToken: 'CV:AVAXWBTC',
		// 	vaultTokenContract: '0xBaEb176d1713A8430aC66F640d26A7044a1F6031',
		// 	gauge: '0xb47ab29bE6FF24c11D8BE7596CD3a55cB775Bc71',
		// },
		usdcjoe: {
			url: 'https://traderjoexyz.com/pool/0x3bc40d4307cd946157447cd55d70ee7495ba6140/AVAX',
			tokenPoolContract: externalPools.steakhut.usdcjoe.pool,
			token: 'USDCJOE',
			tokenContract: externalPools.steakhut.usdcjoe.token,
			vault: '0x25D03Ed23017D0c60B7dA75E36622E5dB2890045',
			vaultToken: 'CV:USDCJOE',
			vaultTokenContract: '0xcF5ea89d154df7f6AAB78aC315CD863e04c99a69',
			gauge: '0x56bb716d69Ca8942784b6456a3c841766692f36A',
		},
		avaxusdc: {
			url: 'https://traderjoexyz.com/pool/0xf4003f4efbe8691b60249e6afbd307abe7758adb/AVAX',
			tokenPoolContract: externalPools.traderjoe.avaxusdc.pool,
			token: 'AVAXUSDC',
			tokenContract: externalPools.traderjoe.avaxusdc.token,
			vault: '0x366aD3d07ceB7077ce0f49b897137a83a98e1cBc',
			vaultToken: 'CV:AVAXUSDC',
			vaultTokenContract: '0x4B990cdf70e5A4Aa0E3daDAae272164C8C4c5402',
			gauge: '0x26555D06587b1059b0db1784F84B7BE6234014DB',
		},
	},
	pools: {
		'TraderJoe YAXIS/WAVAX': {
			active: true,
			legacy: false,
			type: 'traderjoe',
			liquidId: `${currencies.ERC677.yaxis}/WAVAX`,
			lpAddress: '0xa3268e6D1aEF5DFB3Eb345d35d925cd2A64c5E87',
			lpUrl: `https://traderjoexyz.com/pool/${currencies.ERC677.yaxis}/AVAX`,
			lpTokens: [
				{
					tokenId: 'yaxis',
				},
				{
					tokenId: 'wavax',
				},
			],
			tokenAddress: currencies.ERC677.yaxis,
			name: 'TraderJoe YAXIS/WAVAX',
			symbol: 'TraderJoe YAXIS-WAVAX',
			tokenSymbol: 'TRADERJOE_LP',
			icon: '',
			rewards: 'TraderJoe YAXIS/WAVAX',
		},
	},
	currencies,
	external,
	externalPools,
}

export default mainnet
